import React, {useState} from "react"
import {graphql, Link, useStaticQuery} from "gatsby";
import * as styles from "./navbar.module.css"

const Navbar = () => {
    const [isExpanded, toggleExpansion] = useState(false)
    const {allWpMenuItem} = useStaticQuery(
        graphql`
      query {
        allWpMenuItem(filter: { locations: { eq: GATSBY_HEADER_MENU } }) {
            nodes {
              id
              label
              title
              path
              parentId
            }
        }
      }
    `
    )

    const flatListToHierarchical = (
        data = [],
        {idKey = "id", parentKey = "parentId", childrenKey = "children"} = {}
    ) => {
        const tree = []
        const childrenOf = {}
        data.forEach(item => {
            const newItem = {...item}
            const {[idKey]: id, [parentKey]: parentId = 0} = newItem
            childrenOf[id] = childrenOf[id] || []
            newItem[childrenKey] = childrenOf[id]
            parentId
                ? (childrenOf[parentId] = childrenOf[parentId] || []).push(newItem)
                : tree.push(newItem)
        })
        return tree
    }

    const hoverIn = (id) => {
        document.getElementById(`subMenu-${id}`).style.display = "block"
    }

    const hoverOut = (id) => {
        document.getElementById(`subMenu-${id}`).style.display = "none"
    }

    const toggleMenuItem = (id) => (event) => {
        let ele = document.getElementsByClassName('dropdown-menu')
        for (let i = 0; i < ele.length; i++ ) {
            ele[i].style.display = "none"
        }
        document.getElementById(`subMenu-${id}`).style.display = "block"
        event.stopPropagation()
    }

    return (
        <nav className={`flex flex-wrap items-center justify-between bg-gradient-to-r bg-greeny ${styles.nav}`}>
            <div className="container mx-auto">
                <div className="block lg:hidden">
                    <button
                        onClick={() => toggleExpansion(!isExpanded)}
                        style={{color: "#000000"}}
                        className="flex items-center px-3 py-2 text-white border border-white rounded hover:text-white hover:border-white"
                    >
                        <svg
                            className="w-3 h-3 fill-current"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <title>Menu</title>
                            <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"/>
                        </svg>
                    </button>
                </div>
                <div
                    className={`${
                        isExpanded ? `block` : `hidden`
                    } w-full block flex-grow lg:flex lg:items-center lg:w-auto`}
                >
                    <div className="text-sm lg:flex-grow">
                        {flatListToHierarchical(allWpMenuItem.nodes).map(item =>
                            <div className={`${styles.dropdown} lg:inline-block relative`}
                                 key={item.id}
                                 onMouseEnter={() => hoverIn(item.id)}
                                 onMouseLeave={() => hoverOut(item.id)}
                            >
                                <Link
                                    to={item.path}
                                    role="link"
                                    className={`${styles.menuItem} block mt-4 mr-4 md:inline-block lg:mt-0`}
                                >
                                    {item.label}
                                </Link>
                                <svg xmlns="http://www.w3.org/2000/svg" className={`h-4 w-4 lg:hidden ${styles.menuIcon}`} fill="none"
                                     onClick={toggleMenuItem(item.id)} viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                          d="M9 5l7 7-7 7"/>
                                </svg>
                                <ul className={`dropdown-menu lg:absolute text-gray-700 pt-1 z-50 ${styles.subMenu}`} id={`subMenu-${item.id}`}>
                                    {item.children.map(childMenu =>
                                        <li className={styles.subMenuItems} key={childMenu.path}>
                                            {childMenu.path.indexOf("https") === 0 ?
                                                <a href={childMenu.path} role="button" rel="noreferrer" target="_blank">{childMenu.label}</a> :
                                                <Link className={styles.item}
                                                      to={childMenu.path}>{childMenu.label}</Link>}

                                        </li>
                                    )}
                                </ul>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </nav>
    )
}

export default Navbar
