import {Link} from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import {StaticImage} from "gatsby-plugin-image"
import Navbar from "./navbar";
import * as styles from "./header.module.css"

function Header({siteTitle}) {
    return (
        <div className="header-wrapper">

            <header>
                <div className="container mx-auto">
                    <div className="flex flex-wrap">
                        <ul className="top-navbar">
                            <li className="float-left top-home-item hidden md:block">
                                <Link to={`/`}>
                                    Home
                                </Link>
                            </li>
                            <li className="float-left top-connect-item hidden md:block">
                                <a href="https://www.facebook.com/pages/SouthEastern-Illinois-Electric-Coop/304183499708764" target="_blank" rel="noopener noreferrer">
                                    Connect
                                </a>
                            </li>
                            <li className={`float-left top-phone-item ${styles.mobileItems}`}>
                                <span className={`separator ${styles.phoneText}`}>618-273-2611 or (800) 833-2611</span>
                            </li>
                            <li className={`float-left top-outage-item ${styles.mobileItems}`}>
                                <span className={`separator ${styles.phoneText}`}>Report an Outage · (877) 399-8405</span>
                            </li>
                        </ul>
                    </div>
                    <div className="flex flex-wrap py-2">
                        <div className="w-full md:w-6/12">
                            <Link to={'/'}>
                                <StaticImage
                                    src="../images/seiec-logo.png"
                                    alt="Seiec logo"
                                    placeholder="blurred"
                                    layout="constrained"
                                />
                            </Link>
                        </div>
                        <div className="w-full md:w-6/12 text-center hidden md:block">
                            <a href="https://www.touchstoneenergy.com/" target="_blank" rel="noopener noreferrer">
                                <StaticImage
                                    src="../images/touchstone-energy.png"
                                    alt="Touchstone energy logo"
                                    placeholder="blurred"
                                    layout="constrained"
                                />
                            </a>
                        </div>
                    </div>
                </div>
                <Navbar/>
            </header>
        </div>
    )
}

Header.propTypes = {
    siteTitle: PropTypes.string,
}

Header.defaultProps = {
    siteTitle: ``,
}

export default Header
