import React from "react"
import {StaticImage, getImage} from "gatsby-plugin-image";
import {useStaticQuery, graphql} from "gatsby";
import {convertToBgImage} from "gbimage-bridge"
import BackgroundImage from 'gatsby-background-image'
import * as styles from "./footer.module.css"

const Footer = () => {
    const {placeholderImage} = useStaticQuery(
        graphql`
      query {
        placeholderImage: file(relativePath: { eq: "footer-bg.png" }) {
          childImageSharp {
            gatsbyImageData(
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
    `
    )

    const image = getImage(placeholderImage)
    const bgImage = convertToBgImage(image)

    return (
        <BackgroundImage
            Tag="footer"
            {...bgImage}
            preserveStackingContext
            style={{
                // Defaults are overwrite-able by setting one or each of the following:
                backgroundSize: 'contain',
                backgroundPosition: '50% 0',
                backgroundRepeat: 'repeat-x',
            }}
            backgroundColor={`#006633`}
            className="pt-8 pb-6"
        >
            <div className="container mx-auto px-4">
                <div className={`flex flex-wrap ${styles.footer}`}>
                    <div className="w-full md:w-4/12 px-4">
                        <p className="pb-4">
                            Office Hours 8am - 4pm Monday - Friday
                        </p>
                        <table>
                            <tbody>
                            <tr>
                                <td className="pr-6">
                                    <p>
                                        <a className={styles.siteBy} href="mailto:info@seiec.com">info@seiec.com</a>
                                        <br/>
                                        100 Cooperative Way<br/>
                                        Carrier MIlls, Illinois 62917
                                    </p>
                                </td>
                                <td>
                                    <p>(800) 833-2611<br/>
                                        (618) 273-2611&nbsp; &nbsp;<br/>
                                        Fax (618) 273-3886
                                    </p>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="w-full md:w-4/12 px-4 text-center">
                        <div className="copy-dev">
                            Copyright SouthEastern Illinois Electric Cooperative, Inc. {(new Date().getFullYear())} all rights reserved.
                        </div>
                        <div className="pt-6">
                            <a href="https://www.daptontechnologies.com/" target="_blank" rel="noopener noreferrer"
                               className={styles.siteBy}>
                                Site by Dapton Technologies
                            </a>
                        </div>
                    </div>
                    <div className="w-full md:w-4/12 px-4">
                        <StaticImage
                            src="../images/seiec-logo-footer.png"
                            alt="Seiec white logo"
                            placeholder="blurred"
                            layout="constrained"
                        />
                    </div>
                </div>
            </div>
        </BackgroundImage>
    )
}

export default Footer
