import React from "react"
import PropTypes from "prop-types"
import {useStaticQuery, graphql} from "gatsby"
import Header from "./header"
import Footer from "./footer";
import {getImage} from "gatsby-plugin-image";
import {convertToBgImage} from "gbimage-bridge";
import BackgroundImage from 'gatsby-background-image'

const Layout = ({children, feature}) => {
    const {placeholderImage, site, backgroundImage, featureImage} = useStaticQuery(
        graphql`
      query {
        placeholderImage: file(relativePath: { eq: "middle-bg.png" }) {
          childImageSharp {
            gatsbyImageData(
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        },
        backgroundImage: file(relativePath: { eq: "middle-content-bg.png" }) {
          childImageSharp {
            gatsbyImageData(
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        },
        featureImage: file(relativePath: { eq: "feature-bg.png" }) {
          childImageSharp {
            gatsbyImageData(
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        },
        site {
        siteMetadata {
          title
        }
      }
      }
    `
    )

    const image = getImage(placeholderImage)
    const bgImage = convertToBgImage(image)

    const middleImage = getImage(backgroundImage)
    const bgMiddleImage = convertToBgImage(middleImage)

    const featureBgImage = getImage(featureImage)
    const bgFeatureImage = convertToBgImage(featureBgImage)

    return (
        <>
            <Header siteTitle={site.siteMetadata.title}/>
            <main>
                <BackgroundImage
                    Tag="div"
                    {...bgImage}
                    preserveStackingContext
                    style={{
                        // Defaults are overwrite-able by setting one or each of the following:
                        backgroundSize: 'auto',
                        backgroundPosition: '0 0',
                        backgroundRepeat: 'repeat',
                    }}
                >
                    <BackgroundImage
                        Tag="div"
                        {...bgFeatureImage}
                        style={{
                            // Defaults are overwrite-able by setting one or each of the following:
                            backgroundSize: 'auto',
                            backgroundPosition: '0 100%',
                            backgroundRepeat: 'repeat-x',
                        }}
                    >
                        <div className="container mx-auto">
                            {feature}
                        </div>
                    </BackgroundImage>
                    <div className="container mx-auto pb-10">
                        <BackgroundImage
                            Tag="div"
                            {...bgMiddleImage}
                            style={{
                                // Defaults are overwrite-able by setting one or each of the following:
                                backgroundSize: 'auto',
                                backgroundPosition: '0 0',
                                backgroundRepeat: 'repeat-x',
                            }}
                        >
                            {children}
                        </BackgroundImage>
                    </div>
                </BackgroundImage>
            </main>
            <Footer/>
        </>
    )
}

Layout.propTypes = {
    children: PropTypes.node.isRequired,
}

export default Layout
